import { createSignal } from 'solid-js';
import { isServer } from 'solid-js/web';

const [hasMountedGTM, setHasMountedGTM] = createSignal(false);

export function insertGtmScript(id: string) {
	if (hasMountedGTM() || isServer) {
		return;
	}

	window.dataLayer = window.dataLayer || [];
	window.dataLayer.push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
	const node = document.getElementsByTagName('script')[0]!;
	const j = document.createElement('script');
	j.async = true;
	j.src = 'https://www.googletagmanager.com/gtm.js?id=' + id;
	node.parentNode!.insertBefore(j, node);
	setHasMountedGTM(true);
}

declare global {
	interface Window {
		dataLayer: Array<unknown>;
	}
}
