import { createContext, onCleanup, onMount, useContext } from 'solid-js';
import { isServer } from 'solid-js/web';
import { insertGtmScript } from './gtm';
import type PostHog from 'posthog-js';
import type { PostHog as PostHogNode } from 'posthog-node';
import type { ParentProps } from 'solid-js';

type PostHogWeb = typeof PostHog;

const Context = createContext<{
	posthog: PostHogWeb | undefined;
	flags: Record<string, boolean | string>;
	overrides: Record<string, boolean | string>;
}>();

type Props = ParentProps & {
	posthog?: PostHogWeb | PostHogNode;
	flags?: Record<string, boolean | string>;
	overrides?: Record<string, boolean | string>;
	gtmId?: string;
};

export function AnalyticsProvider(props: Props) {
	let callbackId: NodeJS.Timeout;
	onMount(() => {
		if (isServer || !props.gtmId) {
			return;
		}

		callbackId = setTimeout(() => {
			insertGtmScript(props.gtmId!);
		}, 2_000);
	});

	onCleanup(() => {
		if (callbackId) {
			clearTimeout(callbackId);
		}
	});
	return (
		<Context.Provider
			value={{
				posthog: props.posthog as PostHogWeb,
				flags: props.flags ?? {},
				overrides: props.overrides ?? {},
			}}
		>
			{props.children}
		</Context.Provider>
	);
}

export function useAnalytics() {
	const ctx = useContext(Context);
	return ctx?.posthog;
}

export function useIsEnabled(key: string, defaultValue: boolean = false, sendEvents: boolean = true) {
	const ctx = useContext(Context);
	if (key in (ctx?.overrides ?? {})) {
		return !!ctx!.overrides[key];
	}

	if (isServer) {
		return !!ctx?.flags[key];
	}

	return !!(ctx?.posthog?.isFeatureEnabled(key, { send_event: sendEvents }) ?? defaultValue);
}
